* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background: #17223b;
  color: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 90vh;
}
.container {  
  border-radius: 2px;  
  overflow: hidden;
  width: 300px;
  max-width: 100%;
}
h2 {
  text-align: center;
  margin-bottom: 20px;  
}
h3 {
    text-align: center;
    margin-bottom: 12px;
    color: #f2317f;
}
#form-react {
  padding: 30px 20px;  
}
.form-control {    
  margin-bottom: 7px;
  padding-bottom: 10px;
  position: relative;
}
.form-control label {
  display: inline-block;
  margin-bottom: 8px; 
  font:weight 200px
}
.form-control input {
  border: none;
  background-color: #f0f0f0;
  display: block;
  font-family: inherit;
  font-size: 20px;
  padding: 1px;
  width: 100%;    
}
.form-control, input:focus {
  outline: none;
}
.form-react button {
  background: #ff0000;
  border:1px solid #f2317f;
  border-radius: 2px;
  color: #f6f6e9;
  display: block;
  width: 100%;
  padding: 10px;
  font-size: 18px;
}
.form-react button:hover {
  background-color: #bf1650;
}
.fail {
  color: #f2317f;
}