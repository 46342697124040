* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


.container {
    border-radius: 2px;
    overflow: hidden;
    width: 100%;
    max-width: 100%;
}
 
div {
     
} 